.collection-product-preview {
  cursor: all-scroll;
}

.collection-product-preview:hover img {
  filter: brightness(0.5) saturate(2) blur(0.65px);
  max-height: 10px;
}

.delete-product-in-collection {
  visibility: hidden;
}

.collection-product-preview:hover .delete-product-in-collection {
  visibility: visible;
}
